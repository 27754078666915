<template>
  <div class="mange">
    <div class="recoment-tab text-main row-center pointer">
      <div
        v-for="(item, index) in mangeTab"
        :key="index"
        :class="tabbarIndex === item.id ? 'tabClass' : ''"
        @click="tabClick(item.id)"
      >
        {{ item.position_name }}
      </div>
    </div>
    <div class="row-center heard-tab-left">
      <div
        v-for="(item, index) in heardTab"
        :key="index"
        class="pointer"
        :class="[
          heardTabIndex === index ? 'heardTabClass' : '',
          heardTabIndex === 3 && heardTabIndex === index ? 'heardCurrent' : '',
        ]"
        @click="headTabClick(index)"
      >
        {{ item.text }} · {{ item.num }}
      </div>
    </div>
    <div class="talent-center">
      <div class="talent-item" v-for="(item, index) in talentList" :key="index">
        <talent-model
          :index="index"
          :item="item"
          :heardTabIndex="heardTabIndex"
          :content="content"
          @setLinkStatue="setLinkStatue"
        ></talent-model>
      </div>
      <div v-if="talentList.length == 0" class="direction no-list">
        <img src="../../../assets/glxt/45.png" class="el-no-list-img" alt="" />
        <span>什么也没有，发布职位靠谱人才马上就位</span>
      </div>
    </div>
  </div>
</template>

<script>
import talentModel from "../components/talent.vue";
import api from "../../../api/enterprises";
import recruiterApi from "../../../api/recruiter";
export default {
  components: { talentModel },

  data() {
    return {
      mangeTab: [],
      tabbarIndex: 0,
      heardTab: [
        {
          text: "待筛选",
          num: 0,
        },
        {
          text: "沟通中",
          num: 0,
        },
        {
          text: "不合适",
          num: 0,
        },
        {
          text: "收藏",
          num: 0,
        },
      ],
      heardTabIndex: 0,
      talentList: [],
      query: {
        position_id: "",
        link_status: 0,
      },
      content: "",
    };
  },

  created() {
    this.simpleList();
    let query = this.$route.query;
    if (query.id) {
      this.heardTabIndex = 3;
      this.myCollection();
    }
  },

  mounted() {
    this.getSay();
  },

  methods: {
    // 获取打招呼语
    getSay() {
      recruiterApi.getSay({ type: 1 }).then((res) => {
        if (res.code == 200) {
          if (res.data.data.length > 0) {
            let data = res.data.data.filter((element, index) => {
              if (element.is_default == 1) {
                return element;
              }
            });
            if (data.length > 0) {
              this.content = data[0].content;
            } else {
              this.content = res.data.data[0].content;
            }
          } else {
            this.content = "打扰了,我司正在招聘该职位的人才，是否方便沟通一下呢";
          }
        }
      });
    },
    setLinkStatue() {
      this.getPushNum(this.query.position_id);
      if (this.heardTabIndex == 3) {
        this.myCollection();
      } else {
        this.posiList();
      }
    },
    getPushNum(id) {
      api.getPushNum({ position_id: id }).then((res) => {
        if (res.code == 200) {
          this.heardTab[0].num = res.data.no_link_num ? res.data.no_link_num : 0;
          this.heardTab[1].num = res.data.link_num ? res.data.link_num : 0;
          this.heardTab[2].num = res.data.no_num ? res.data.no_num : 0;
          this.heardTab[3].num = res.data.collect_num ? res.data.collect_num : 0;
        }
      });
    },
    // 获取相关职位
    posiList() {
      api.perPush(this.query).then((res) => {
        if (res.code == 200) {
          this.talentList = res.data.data;
        }
      });
    },
    // 获取企业发布的职位
    simpleList() {
      let entDetail = JSON.parse(localStorage.getItem("entDetail"));
      let id = entDetail.id;
      api.simpleList({ enterprise_id: id }).then((res) => {
        if (res.code == 200) {
          res.data.unshift({
            id: "",
            position_name: "全部职位",
          });
          this.mangeTab = res.data;
          if (this.heardTabIndex !== 3) {
            this.posiList();
          }

          if (res.data.length > 0) {
            this.tabbarIndex = res.data[0].id;
            this.query.position_id = res.data[0].id;
            this.getPushNum(res.data[0].id);
          }
        }
      });
    },
    // 收藏人才列表
    myCollection() {
      api.myCollection().then((res) => {
        if (res.code == 200) {
          this.talentList = res.data.data;
        }
      });
    },
    tabClick(index) {
      this.tabbarIndex = index;
      this.query.position_id = index;
      this.posiList();
      this.getPushNum(index);
    },
    headTabClick(index) {
      this.query.link_status = index;
      this.heardTabIndex = index;
      if (index == 3) {
        this.myCollection();
      } else {
        this.posiList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.mange {
  width: 100%;
  min-height: calc(100vh - 3.375rem);
  background: #fff;
  border-radius: 0 0 0 21px;
  padding: 30px 147px 20px 147px;
  .recoment-tab {
    font-size: 20px;
    margin-bottom: 29px;
    div {
      margin-right: 20px;
    }
    .tabClass {
      color: #126bf9;
      font-size: 24px;
    }
  }
  .heard-tab-left {
    width: 100%;
    height: 39px;
    background: #f8f8f8;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    padding-left: 62px;
    div {
      height: 100%;
      line-height: 2.4375rem;
      position: relative;
      margin-right: 3.4375rem;
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-bottom: 4px solid #126bf9;
        left: 0;
        bottom: 0;
        transition: 0.3s;
      }
    }
    .heardTabClass {
      color: #126bf9;
      &:after {
        width: 4rem;
      }
    }
    .heardCurrent {
      &:after {
        width: 3.125rem;
      }
    }
  }
  .talent-center {
    width: 100%;
    .talent-item {
      width: 100%;
      margin-bottom: 8px;
    }
    .talent-item:last-child {
      margin-bottom: 0;
    }
    .no-list {
      justify-content: center;
      font-weight: 400;
      color: #bfbfbf;
      font-size: 14px;
      margin-top: 90px;
      .el-no-list-img {
        width: 163px;
        height: 145px;
        margin-bottom: 23px;
      }
    }
  }
}
</style>
